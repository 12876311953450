.ColorInput {
  &__label {
    color: #6f6f6f;
    font-size: 12px;
    line-height: 20px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  &__input {
    position: relative;

    [class~="Polaris-TextField__Input"] {
      padding-right: 3.6rem;
      color: #121212;
      font-size: 14px;
    }

    [class~="Polaris-TextField__Backdrop"] {
      box-shadow: inset 0px 0px 0px 1px rgba(102, 113, 123, 0.25),
        inset 0px 1px 2px rgba(102, 113, 123, 0.21);
      border: none;
    }
  }

  &__button {
    border: none;
    border-radius: 2px;
    width: 3.2rem;
    height: 3.2rem;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }

  &__colorPreview {
    position: absolute;
    height: 3.6rem;
    right: 2px;
    top: 0;
    bottom: 0;
    z-index: 30;
  }

  &__colorPopover {
    padding: 1rem;
  }
}
